import React, { useState, useEffect } from "react";
import style from "./inquiry.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import InqueryTable from "./components/table/InquiryTable";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getBuyerList,
  getLeadList,
} from "../../store/buyerLead/buyerLeadAction";
import { Box, Pagination, Stack, Typography } from "@mui/material";

export default function InquiryManagement() {
  const { buyerLeadList, total } = useSelector((state) => state.buyerLead);
  const [searchText, setSearchText] = useState("");
  const [datas, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [filter, setFilter] = useState({
    status: "1",
    dealerId: localStorage.getItem("dealerId"),
  });
  const [reset, setReset] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [totalPageCount, setTotalPageCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleSearch = () => {
    let filtered = datas;

    if (searchText) {
      filtered = filtered.filter(
        (item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.phone.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (selectedDate) {
      filtered = filtered.filter((item) =>
        item.created_at.startsWith(selectedDate)
      );
    }

    setFilterData(filtered);
  };

  const [pagination, setPagination] = useState({
    pageSize: 1,
    pageIndex: 0,
  });

  useEffect(() => {
    handleSearch();
  }, [searchText, selectedDate, datas]);

  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const response = await getLeadList({ page: pagination.pageIndex });
        console.log("inquiremanagment-response", response);
        console.log(response.data);

        setData(response.data);
        setFilterData(response.data);
        setCurrentPage(response.currentPage);
        setTotalCount(response.totalCount);
        setTotalPageCount(response.totalPageCount);
        setHasNextPage(response.hasNextPage);
      } catch (error) {
        console.error("Error fetching lead data:", error);
      }
    };

    fetchLeadData();
  }, [pagination.pageIndex]);

  useEffect(() => {
    dispatch(getBuyerList());
  }, []);

  const applyFilter = () => {
    if (Object.values(filter).some((value) => value !== "")) {
      getBuyerList();
    }
  };

  const resetFilter = () => {
    setFilter({
      status: "1",
      dealerId: localStorage.getItem("dealerId"),
    });

    setReset(true);
  };

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, pageIndex: page }));
    setCurrentPage(page);
  };

  const handleNextPage = () => {
    if (hasNextPage) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 }));
    }
  };

  const handlePreviousPage = () => {
    if (pagination.pageIndex > 0) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex - 1 }));
    }
  };

  // useEffect(() => {
  //   setData(buyerLeadList);
  // }, [buyerLeadList]);

  const exportToExcel = () => {
    const dataWithoutAction = buyerLeadList.map(({ action, ...rest }) => rest);
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataWithoutAction);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      xlsx.writeFile(workbook, "buyer_data.xlsx");
    });
  };

  // useEffect(() => {
  //   let filterData = data;

  //   if (searchText) {
  //     filterData = data.filter((ele) =>
  //       ele.name.toLowerCase().includes(searchText.toLowerCase())
  //     );
  //   }

  //   if (status) {
  //     filterData = filterData.filter((ele) => ele.status === status);
  //   }

  //   if (!searchText && !status) {
  //     filterData = buyerLeadList;
  //   }

  //   setData(filterData);
  // }, [searchText, status]);

  return (
    <div className={style["container"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 0",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Inquiry ({totalCount})
        </Typography>
      </Box>

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <SearchBar
            placeholder={"Search Name or Phone"}
            handleChange={handleChange}
          />

          <div className={style["date-picker-container"]}>
            {/* <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              placeholderText="Select Date"
            /> */}

            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              className={style["date-change"]}
              placeholder="Select Date"
              style={{ color: selectedDate ? "black" : "gray" }}
            />
            {/* <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="de"
              sx={{
                "& .MuiStack-root": {
                  paddingTop: "10px !important",
                },
              }}
            >
              <DatePicker
                size="small"
                openTo="day"
                views={["year", "month", "day"]}
                value={selectedDate || null}
                onChange={(newValue) => {
                  if (newValue) {
                    handleDateChange(newValue);
                  }
                }}
                required={true}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    height: "36px !important",
                  },
                  "& .MuiStack-root": {
                    paddingTop: "0px !important",
                  },
                }}
              />
            </LocalizationProvider> */}

            {/* <SelectableButton
            trailingIcon={R.ic_arrow_down}
            containerStyle={style["selectable-button-container-style"]}
            handleChange={(e) => setStatus(e.target.value)}
            items={[
              { label: "Pending", value: "PENDING" },
              { label: "Rejected", value: "REJECTED" },
              { label: "Confirmed", value: "CONFIRMED" },
              { label: "Booked", value: "BOOKED" },
              { label: "Done", value: "Done" },
            ]}
            hint={"All Status"}
            value={status}
          /> */}
          </div>
          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={" Filter"}
            fontSize={"1rem"}
            onClick={() => applyFilter()}
          />

          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={"Reset"}
            fontSize={"1rem"}
            onClick={() => resetFilter()}
          />
        </div>

        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["excel-button-container-style"]}
            leadingIcon={R.ic_excel}
            name={"Export Excel"}
            fontSize={"1rem"}
            onClick={exportToExcel}
          />
        </div>
      </div>
      <InqueryTable
        data={filterData} // Ensure this uses filtered data
        pagination={pagination}
        setPagination={setPagination}
        total={total}
      />
      <Stack
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={"20px"}
      >
        <Pagination
          count={totalPageCount}
          page={currentPage}
          onChange={(event, page) => handlePageChange(page)}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </div>
  );
}
