import { ContactPageSharp } from "@mui/icons-material";
import { call, dealerfetchCall, brandcall, carCall } from ".";

class PublicApi {

  brandList() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await brandcall("get", "inventoryService/public/get-make", null, null, token);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getColors() {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await carCall("get", "inventoryService/public/get-color", null, null);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  
  getDealerDeail(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerfetchCall(
            "get",
            "admin-dealer-auth/domin_template_details",
            params,
          );
          
          resolve(res);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })(); // Add parentheses here to invoke the IIFE
    });
  }
  

  modelList(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await brandcall("get", "inventoryService/public/get-model", params, null, token);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  varientList(params) {
    console.log("ppppppppppppp ", params)
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await brandcall("get", "inventoryService/public/get-variant", params, null, token);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getModelData(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await brandcall("get", "inventoryService/public/get-model-list", params, null, token);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }


}

export const publicApi = new PublicApi();
