import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
// import MultiSelect from "../../../../../components/buttons/multiSelect";
import { useNavigate } from "react-router-dom";
import { buyerApi } from "../../../api/buyerLeadApi";

import { toast, ToastContainer } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import MultiSelectTwo from "../../../components/buttons/modelMultiSelect";
import AssignDealerTable from "../../../components/tables/AssignDealers";

const Step2 = ({ setCurrentStep, id }) => {
  const navigate = useNavigate();
  const [dealers, setDealers] = useState([]);
  const [matchingDealers, setMatchingDealers] = useState([]);
  const [tempDealerChoosen, setTempDealerChoosen] = useState({
    dealer_name: "",
  });
  const [basicDetails, setBasicDetails] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  // added dealer code
  const [preferences, setPreferences] = useState({
    dealer_name: [],
    dealerId: null,
    dealercode: null,
  });
  const [payload, setPayload] = useState({
    customerId: +id.id,
    assignedDealer: [],
    unassignedDealer: [],
  });

  console.log("payloadpayload ", payload);

  //const navigate = useNavigate();

  console.log("dealers for dropdown ", dealers);

  const customer = async (params = {}) => {
    try {
      const res = await buyerApi.getCustomerPreferenceDetails(+id.id);
      setBasicDetails({
        name: res?.data?.name || null,
        mobile: res?.data?.mobile || null,
        email: res?.data?.email || null,
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    customer();
  }, []);

  const dealerList = async () => {
    try {
      const res = await buyerApi.getDealerList({ pagesize: 100, status: 3 });
      setDealers(res?.data);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch dealer list.");
    }
  };

  const matchingDealer = async () => {
    try {
      const res = await buyerApi.getMatchingDealer(+id.id);
      setMatchingDealers(res?.data);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch matching dealers.");
    }
  };

  useEffect(() => {
    dealerList();
    matchingDealer();
  }, []);

  const handleDealerChange = (selectedItems) => {
    console.log("selectedItems selectedItems ", selectedItems);
    const labels = selectedItems.map((item) => item.label.split(" (")[0]);
    const values = selectedItems.map((item) => item.value);
    const dealerCode = selectedItems.map((item) => item.value2);

    setTempDealerChoosen((prevPreferences) => ({
      ...prevPreferences,
      dealer_name: selectedItems,
    }));

    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      dealer_name: labels,
      dealerId: values,
      dealercode: dealerCode,
    }));
  };

  const handleSave = async () => {
    try {
      const response = await buyerApi.addCustomerAssignDealer(payload);

      toast.success("Dealers assigned successfully!");

      matchingDealer();
      if (response.status == 1) {
        setTimeout(() => {
          navigate("/lead/customer-lead");
        }, 500);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to assign dealers.");
    }
  };

  const handleAdd = () => {
    const existingDealerIds = new Set(
      matchingDealers.map((dealer) => dealer.dealerId)
    );

    const preferenceMap = new Map();
    preferences.dealerId.forEach((id, index) => {
      preferenceMap.set(id, preferences.dealer_name[index]);
    });

    const newDealers = preferences.dealerId.reduce((acc, id, index) => {
      if (!existingDealerIds.has(id)) {
        acc.push({
          dealerId: id,
          dealershipName: preferences.dealer_name[index],
          dealerCode: preferences.dealercode[index],
          selected: true,
        });
      }
      return acc;
    }, []);

    const updatedDealers = [...matchingDealers, ...newDealers];
    setMatchingDealers(updatedDealers);
  };

  return (
    <div className={style["container"]}>
      <div className={style["dealer-info-container"]}>
        <div className={style["wrapper"]}>
          <div className={style["heading"]}> Name</div>
          <div className={style["value"]}>{basicDetails?.name || ""}</div>
        </div>
        <div className={style["wrapper"]}>
          <div className={style["heading"]}>Phone Number</div>
          <div className={style["value"]}>
            {basicDetails?.mobile || ""}
          </div>
        </div>
        <div className={style["wrapper"]}>
          <div className={style["heading"]}>Email Id</div>
          <div className={style["value"]}>
            {basicDetails?.email || ""}
          </div>
        </div>
      </div>

      <div className={style["mid-container"]}>
        <div className={style["two-tabs-section"]}>
          <div className={style["input-label"]}>
            <p className={style["label"]}>Select Dealers : </p>
            <MultiSelectTwo
              containerStyle={style["selectable-button-container-style"]}
              name="dealer"
              handleChange={handleDealerChange}
              items={dealers.map((dealer) => ({
                label: `${dealer.dealership_name} (${dealer.dealercode})`,
                value: dealer.id,
                value2: dealer?.dealercode,
              }))}
              selectedItems={tempDealerChoosen.dealer_name}
              hint={"Select Dealers"}
              isMulti={true}
            />
            <button onClick={handleAdd} className={style["add-button"]}>
              + Add
            </button>
          </div>
          <div className={style["add-button-section"]}></div>
        </div>

        <AssignDealerTable data={matchingDealers} setPayload={setPayload} />

        <div className={style["save-button-section"]}>
          <button onClick={handleSave} className={style["save-button"]}>
            Save
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Step2;
