import { EDIT_INQUIRE_LIST } from "../types";
import { buyerApi } from "../../api/buyerLeadApi";

export const editInquireList = (payload) => (dispatch) => {
  return buyerApi.updateInquireLead(payload).then(
    (data) => {
      dispatch({
        type: EDIT_INQUIRE_LIST,
        payload: payload,
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
