import React, { useState, useEffect } from "react";
import style from "./testimonials.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BlogTable from "./table/TestimonialList";
import { Box, Typography } from "@mui/material";

function Testimonials() {
  const [value, setValues] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = (e) => {
    let filterd = value;

    if (searchText) {
      filterd = filterd.filter((item) =>
        item.customer_name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    setFilterData(filterd);
  };

  useEffect(() => {
    handleSearch();
  }, [searchText, value]);

  return (
    <div className={style["container"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 0",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Testimonials ({totalCount})
        </Typography>
      </Box>

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <SearchBar
            placeholder={"Search Name or Phone"}
            handleChange={handleChange}
          />
        </div>
        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["add-blogs-button"]}
            leadingIcon={R.ic_add_white}
            name={"Add New Testinomial"}
            fontSize={"1rem"}
            linkTo={"/create-testinomial"}
            onClick={() => navigate("/create-testinomial")}
          />
        </div>
      </div>
      <BlogTable
        value={filterData}
        setValues={setValues}
        totalCount={totalCount} // Pass total count to BlogTable
        setTotalCount={setTotalCount} // Pass setTotalCount to update from table
      />
    </div>
  );
}

export default Testimonials;
