import React from "react";

export default function PrimaryHeading({
  title,
  count,
  fontSize,
  fontWeight,
  onClick,
}) {
  return (
    <div style={{ display: "flex" }} onClick={onClick}>
      <p
        style={{
          fontSize: fontSize,
          fontWeight: fontWeight,
          color: "var(--heading-gray)",
          marginBottom: "20px"
        }}
      >
        {title}
      </p>
      {count && (
        <p
          style={{
            fontSize: "1.75rem",
            fontWeight: 600,
            color: "var(--heading-gray)",
            marginLeft: "10px",
          }}
        >
          {"(" + count + ")"}
        </p>
      )}
    </div>
  );
}
