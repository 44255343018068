import { GET_CAR_LIST, CREATE_CAR_DETAIL, EDIT_CAR_DETAIL, GET_DEALER_ADDRESS, GET_DEALER_USERS, GET_ALL_DEALERS, GET_ALL_PURCHESAR, GET_ALL_SOURCE } from "../types";

const initialState = {
  carList: [],
  total: 10,
  dealerAddress: [],
  dealerUsers: [],
  carDealers: [],
  purchaser : [],
  source : []
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CAR_DETAIL:
      //   const updateBuyerLead = state.buyerLeadList.map((buyerLead) => {
      //     if (buyerLead.id === payload.id) {
      //       return { ...buyerLead, ...payload };
      //     }
      //     return buyerLead;
      //   });
      return {
        ...state,
        carList: payload,
      };
    case EDIT_CAR_DETAIL:
      const updateBuyerLead = state.carList.map((buyerLead) => {
        if (buyerLead.car_id === payload.car_id) {
          return { ...buyerLead, ...payload };
        }
        return buyerLead;
      });
      return {
        ...state,
        carList: updateBuyerLead,
      };

    case GET_CAR_LIST:
      return {
        ...state,
        carList: payload?.data,
        total: payload?.total,
      };

    case GET_DEALER_ADDRESS:
      return {
        ...state,
        dealerAddress: payload,
      };
    case GET_DEALER_USERS:
      return {
        ...state,
        dealerUsers: payload,
      };
    case GET_ALL_DEALERS:
      return {
        ...state,
        carDealers: payload,
      };
    case GET_ALL_PURCHESAR:
      return {
        ...state,
        purchaser: payload,
      };
    case GET_ALL_SOURCE:
      return {
        ...state,
        source: payload,
      };

    default:
      return state;
  }
}
