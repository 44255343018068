import * as Yup from 'yup';

class ValidationSchema {
    soldManagement(leadOpenPnl, purchaseSeen) {
        const isValidationDisabled = leadOpenPnl === "1" || purchaseSeen === "1";

        return Yup.object({
            sold_date: isValidationDisabled
                ? Yup.date().nullable()
                : Yup.date().required('Sold date is required'),
            sold_amount: isValidationDisabled
                ? Yup.string().nullable()
                : Yup.string().required('Sold amount is required'),
            sold_to: isValidationDisabled
                ? Yup.string().nullable()
                : Yup.string().required('Sold to is required'),
        });
    }
}

export default new ValidationSchema();
