import React, { useState, useEffect } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import style from "./customerLead.module.scss";

export default function AssignDealerTable({ data = [], setPayload }) {
  console.log("datadatadatadatadata ", data);

  const [checkedDealers, setCheckedDealers] = useState({});

  useEffect(() => {
    const initialCheckedDealers = data.reduce((acc, dealer) => {
      acc[dealer.dealerId] = true;
      return acc;
    }, {});

    setCheckedDealers(initialCheckedDealers);

    // Update payload to include only dealerIds where leadId is NOT present
    setPayload((prevPayload) => {
      return {
        ...prevPayload,
        assignedDealer: [
          ...new Set([
            ...prevPayload.assignedDealer,
            ...data
              .filter((dealer) => !dealer.leadId)
              .map((dealer) => dealer.dealerId), // Filter out dealers with a leadId
          ]),
        ],
      };
    });
  }, [data]);

  const handleCheckboxChange = (dealerId) => {
    setCheckedDealers((prevChecked) => {
      const isChecked = !prevChecked[dealerId];

      setPayload((prevPayload) => {
        // If the checkbox is being checked
        if (isChecked) {
          return {
            ...prevPayload,
            assignedDealer: [
              ...new Set([...prevPayload.assignedDealer, dealerId]),
            ], // Add dealerId if not already present
            unassignedDealer: prevPayload.unassignedDealer.filter(
              (id) => id !== dealerId
            ), // Remove from unassignedDealer if present
          };
        } else {
          // If the checkbox is being unchecked
          return {
            ...prevPayload,
            assignedDealer: prevPayload.assignedDealer.filter(
              (id) => id !== dealerId
            ), // Remove from assignedDealer if present
            unassignedDealer: [
              ...new Set([...prevPayload.unassignedDealer, dealerId]),
            ], // Add dealerId if not already present
          };
        }
      });

      return {
        ...prevChecked,
        [dealerId]: isChecked,
      };
    });
  };

  const columns = [
    {
      accessorKey: "checkbox",
      header: "",
      cell: ({ row }) => {
        const { dealerId, leadStatusId } = row.original;
        const isChecked = checkedDealers[dealerId] || false;
        const isDisabled = leadStatusId !== 1 && leadStatusId !== undefined;

        return (
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => handleCheckboxChange(dealerId)}
            disabled={isDisabled}
          />
        );
      },
    },
    {
      accessorKey: "dealershipName",
      header: "Dealership Name",
    },
    {
      accessorKey: "dealerCode",
      header: "Dealer Code",
    },
    {
      accessorKey: "leadAssignCount",
      header: "Lead Count",
    },
    {
      accessorKey: "recommendation",
      header: "Recommendation",
      cell: ({ row }) => {
        // Check if leadId is present
        const { leadId } = row.original;
        const text = leadId ? "Lead Sent" : "Recommended ";
        const style = {
          color: leadId ? "green" : "blue",
        };

        return <span style={style}>{text}</span>;
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div
      className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
    >
      <table
        className={`${style["table-wrapper"]} table table-border table-hover`}
        cellSpacing={"5px"}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup?.id} className={style["table-header-wrapper"]}>
              {headerGroup?.headers?.map((header) => (
                <th key={header?.id} className={style["table-header"]}>
                  {flexRender(
                    header?.column?.columnDef?.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table?.getRowModel()?.rows?.map((row) => (
            <tr key={row?.id} className={style["table-row"]}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(
                    cell?.column?.columnDef?.cell,
                    cell?.getContext()
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
