import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./buyerEditLeads.module.scss";
import { useDispatch } from "react-redux";
import { editInquireList } from "../../../../store/inquiryLead/inquiryLeadAction";
import { buyerApi } from "../../../../api/buyerLeadApi";
import BackButton from "../../../../components/buttons/BackButton";

export default function InquireEditLeads() {
  const { state } = useLocation();
  const [inquireType, setInquireType] = useState([]);
  const [selectInquire, setSelectInquire] = useState(0);

  console.log("inquireType", inquireType);

  useEffect(() => {
    const fetchData = async () => {
      const res = inquireType.find(
        (item) => item?.name === state?.data?.inquiry_name
      );
      setSelectInquire(res?.id);
    };

    if (inquireType.length > 0) {
      fetchData();
    }
  }, [inquireType, state?.data?.inquiry_name]);

  async function fetchInquireType() {
    try {
      const res = await buyerApi.getInqueryType();
      setInquireType(res?.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchInquireType();
  }, []);

  const dispatch = useDispatch();

  const initialValues = {
    name: state?.data?.name || "",
    phone: state?.data?.phone || "",
    email: state?.data?.email || "",
    description: state?.data?.description || "",
    inquery_type: selectInquire || "",
    id: state?.data?.id || "",
  };

  const navigate = useNavigate();

  const handleSubmit = (values) => {
    dispatch(editInquireList(values))
      .then((res) => {
        console.log("response ==>", res);
        navigate("/inquiry-management");
      })
      .catch((error) => {
        console.log("error ==>", error);
      });
  };

  return (
    <div className={style["container"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

          alignItems: "center",

          padding: "15px ",
        }}
      >
        <BackButton
          onClick={() => {
            navigate("/inquiry-management");
          }}
        />
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Edit Inquiry
        </Typography>
      </Box>
      <Card sx={{ mt: 2, mb: 2 }}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="phone"
                      name="phone"
                      label="Phone No"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="email"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      fullWidth
                      freeSolo
                      id="inquery_type"
                      name="inquery_type"
                      value={
                        inquireType.find(
                          (res) => res.id === values.inquery_type
                        )
                          ? {
                              label: inquireType.find(
                                (res) => res.id === values.inquery_type
                              ).name,
                              value: values.inquery_type,
                            }
                          : null
                      }
                      onChange={(event, newValue) => {
                        console.log("newvalue", newValue, values.inquery_type);
                        setFieldValue("inquery_type", newValue?.value);
                      }}
                      onBlur={handleBlur}
                      options={inquireType.map((res) => ({
                        label: res.name,
                        value: res.id,
                      }))}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Inquire Type"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      label="Description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Box mt={2} display="flex" justifyContent="flex-end">
                  <Button
                    sx={{
                      backgroundColor: "#121b5b",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#121b5b",
                        color: "#fff",
                      },
                    }}
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
}
