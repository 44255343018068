import { EDIT_INQUIRE_LIST } from "../types";

const initialState = {
    inquireData: {       // Adding inquireData to the initial state
        inquireDetails: {} // Default value for inquireDetails
    },
    InquireLeadList: [],
    total: 10,
}


export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case EDIT_INQUIRE_LIST:
            return {
                ...state,
                inquireData: { ...state?.inquireData, inquireDetails: payload }
            }

        default:
            return state;
    }
}